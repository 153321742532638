import React from 'react'
import { Image, useTheme, View, Flex, Text, Heading, Button, useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import rhr from 'src/assets/brand/rhr-bold-smaller.png'


const components = {
    Header() {
        const { tokens } = useTheme();
      
        return (
          <View textAlign="center" padding={tokens.space.large}>
              <Image alt="logo" src = { rhr }  width={100} height={100} />

      
          </View>
          
        );
    },
    Footer() {
        const { tokens } = useTheme();
      
        return (
          <Flex justifyContent="center" padding={tokens.space.medium}>
            <Text color={tokens.colors.white}>&copy; 2023 Reload HR</Text>
          </Flex>
        );
    },
    SignIn: {
      /*
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },    
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <Flex
          justifyContent="center"
        marginTop="2rem"
        marginBottom="2rem"
          >
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={() => {
                Auth.federatedSignIn({provider: 'rhr-azure-aad'}).then(cred => {
                    // If success, you will get the AWS credentials
                    console.log("Cred: " + cred);
                }).then(user => {
                    // If success, the user object you passed in Auth.federatedSignIn
                    console.log("User: " + user);
                }).catch(e => {
                    console.log("ERROR: " + e)
                })
            }}
              // size="large"
              variation="primary"
            >
              Sing In with Azure 
            </Button>          
          </View>
          </Flex>
        );

      }              */

    },


}


export default components;

