import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
            EmployeeCard: {
                header: 'Employee Details',
                History: "History",
                gender: "gender",
                Field: "Field",
                Value: "Value",
                TechnicalFieldName: "Technical Name"
            },
            EmployeeDiff: {
              Field: 'Field',
              Old: 'Old',
              New: 'New',
              Modified: "Modifired on",
              EmployeeDifference: "Employee Difference"
            },
            Navigation: {
                Documents: "Documents",
                Overview: "Overview",
                Employees: "Employees",
                Logs: "Logs",
                Jobs: "Jobs",
                Webhooks: "Webhooks",
                WebhookLogs: "Logs",                
                DocumentTemplates: "Document Template",
                Dashboard: "Dashboard"
            },
            Buttons: {
                Create: "Create",
                Close: "Close",
                Show: "Show",
                Update: "Update",
                Delete: "Delete"
            },
            Webhooks: {
              Create: "Create Webhook",
              SuccessMsg: "Webhook created successfully, you can close this view.",
              List: "List of webhooks",
              Change: "Change Webhook",
              Field: "Field",
              Description: "Description",
              Label: "Label",
              TimeDelta: "Time Delta",
              PersonioFieldName: "Personio-Feld",
              Value: "Wert",
              EndpointFieldName: "Endpoint-Feld",
              Method: "Methode"
                  
            },
            Dashboard: {
              Welcome: "Hello",
              CustomerName: "Customer Name",
              CustomerContact: "Customer Contact",
              Dashboard: "Dashboard"
            },
            Employee: {
              List: "List of Employees",
              FirstName: "First Name",
              LastName: "Last Name",
            },
            Breadcrumb: {
              CreateWebhook: "Create Webhook",
              WebhooksOverview: "Webhooks Overview",
              Dashboard: "Dashboard"
            },
            Common: {
              Yes: "Yes",
              No: "No"
            }                             
        },
      },
      de: {
        translation: {

            Navigation: {
                Documents: "Dokumente",
                Overview: "Übersicht",
                Employees: "Mitarbeiter",
                Logs: "Logs",
                Jobs: "Jobs",
                Webhooks: "Webhooks",
                WebhookLogs: "Logs",
                DocumentTemplates: "Dokumentenvorlagen",
                Admin: "Adminbereich",
                Settings: "Einstellungen"
            },
            Buttons: {
              Create: "Anlegen",
              Close: "Schließen",
              Show: "Anzeigen",
              Update: "Ändern",
              Delete: "Löschen",
              Save: "Speichern"
            },
            Webhooks: {
              Create: "Webhook anlegen",
              SuccessMsg: "Webhook wurde erfolgreich angelegt, das View kann geschlossen werden.",
              List: "Liste der Webhooks",
              Change: "Webhook ändern",
              Delete: "Löschen",
              Field: "Feld",
              Description: "Beschreibung",
              Label: "Beschriftung",
              TimeDelta: "Vorlauf",
              Recurring: "Datumsbezogen"
               
            },
            Dashboard: {
              Welcome: "Hallo",
              CustomerName: "Kundenname",
              CustomerContact: "Kundenkontakt",
            },
            Employee: {
              List: "Liste der Mitarbeiter",
              FirstName: "Vorname",
              LastName: "Nachname",
              LastModified: "Letzte Änderung",
              
            },
            EmployeeDiff: {
              Field: 'Feld',
              Old: 'Alt',
              New: 'Neu',
              Modified: "Geändert am",
              EmployeeDifference: "Änderungsverlauf"
            },
            EmployeeCard: {
              header: "Mitarbeiterdetails",
              History: "Verlauf",
              gender: "Geschlecht",
              Field: "Feld",
              Value: "Wert",
              TechnicalFieldName: "Tech. Name"
             
            },
            Breadcrumb: {
              CreateWebhook: "Webhook anlegen",
              WebhooksOverview: "Webhooks Übersicht",
              Dashboard: "Dashboard",
              EmployeesOverview: "Mitarbeiterübersicht",
              DisplayEmployee: "Mitarbeiterdetails",
              Documents: "Bewerber und Arbeitsverträge"
            },
            Signin: {
              Username: "Benutzername",
              UsernameEnter: "Benutzernamen hier eintragen",
              EmailEnter: "Email hier eintragen",
              PasswordEnter: "Passwort hier eintragen",
              Password: "Passwort"
            },
            Common: {
              Yes: "Ja",
              No: "Nein"
            }
        },
      },
    },
    lng: 'de', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });